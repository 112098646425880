import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type { ContactDto } from '#edsn/api/pie-bff';
import { useGetTeamId } from '#edsn/api/pie-bff';
import { AddTeamMemberModal } from '#pie/components/add-team-member-modal/AddTeamMemberModal';
import { Card } from '#pie/components/card/Card';
import { Page, PageHeader } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { RemoveTeamMemberButton } from '#pie/components/remove-team-member-button/RemoveTeamMemberButton';
import { Stack } from '#pie/components/stack/Stack';
import { Table } from '#pie/components/table/Table';
import { Text } from '#pie/components/text/Text';

export const TeamDetail = () => {
  const { t: tcom } = useTranslation();
  const { t: tom } = useTranslation('organisationManagement');
  const teamId = useParams()['teamId']!;
  const helper = useMemo(() => createColumnHelper<ContactDto>(), []);
  const { data, isLoading } = useGetTeamId(teamId);

  return (
    <>
      <PageHead
        title={tom('header.title.team', {
          teamDetail: data?.name,
        })}
      />
      <Page>
        <PageHeader
          title={tom('header.title.team', {
            teamDetail: data?.name,
          })}
          className="mb-8"
          backHref={`../teamsbeheer`}
          previousText={tcom('common.button.back')}
        >
          <AddTeamMemberModal teamId={teamId} />
        </PageHeader>

        <div className="flex items-start gap-6">
          <Card className="flex-1 overflow-x-hidden p-4">
            <Stack gap="md">
              {!isLoading && !data?.contacts.length ? (
                <Text className="p-3">{tcom('common.no_results')}</Text>
              ) : (
                <Table
                  isLoading={isLoading}
                  data={data?.contacts}
                  columns={[
                    helper.accessor('fullName', {
                      header: tcom('common.name'),
                      meta: { columnWidth: 200 },
                    }),
                    helper.accessor('email', {
                      cell: info => (
                        <div className="flex">
                          {info.getValue()}
                          <RemoveTeamMemberButton data={info.row.original} teamId={teamId} />
                        </div>
                      ),
                      header: tcom('common.email'),
                    }),
                  ]}
                  getRowId={m => m.id}
                  pagination={{ pageIndex: 0, pageSize: data?.contacts.length || 0 }}
                  sorting={[]}
                />
              )}
            </Stack>
          </Card>
        </div>
      </Page>
    </>
  );
};
