import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ConsumptionType, type CreateDataRequestDto, EnergyType } from '#edsn/api/pie-bff';
import type { FormCheckboxType } from '#pie/components/form/types';
import { Stack } from '#pie/components/stack/Stack';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { FormCheckbox, ConditionalField, FormText } = typedFormFields<CreateDataRequestDto>();

export const ConsumptionTypes = () => {
  const { t } = useTranslation('dataRequest');
  const { watch } = useFormContext<CreateDataRequestDto>();
  const electricitySelected = watch('energyTypesConsumption')?.find(
    energyType => energyType === EnergyType.Electricity
  );
  const options: FormCheckboxType[] = [
    ...(electricitySelected
      ? [
          {
            label: t(`consumption_type.${ConsumptionType.Generate}`),
            value: ConsumptionType.Generate,
          },
        ]
      : []),
    {
      label: t(`consumption_type.${ConsumptionType.Consume}`),
      value: ConsumptionType.Consume,
    },
    {
      label: t(`consumption_type.${ConsumptionType.Other}`),
      renderInput: (
        <ConditionalField
          field="consumptionTypeNote"
          condition={formValue => {
            return !!formValue('consumptionTypes') && formValue('consumptionTypes')!.includes(ConsumptionType.Other);
          }}
        >
          <FormText
            aria-label={t('consumption_type_note')}
            fullWidth
            name="consumptionTypeNote"
            rules={{ maxLength: 100, minLength: 2, required: true }}
          />
        </ConditionalField>
      ),
      value: ConsumptionType.Other,
    },
  ];

  return (
    <ConditionalField
      field="consumptionTypes"
      condition={formValue => {
        return !!formValue('energyTypesConsumption') && formValue('energyTypesConsumption')!.length > 0;
      }}
    >
      <Stack direction="column" gap="sm">
        <FormCheckbox
          name="consumptionTypes"
          label={t('consumption_types')}
          options={options}
          rules={{ required: true }}
        />
      </Stack>
    </ConditionalField>
  );
};
