import { clearCurrentOrganization, getCurrentOrganization } from './oidc-config-local-storage';
import type { OidcConfiguration } from '@axa-fr/oidc-client';

export function createOidcConfiguration(oidcConfig: OidcConfiguration): OidcConfiguration {
  const currentOrganization = getCurrentOrganization();

  if (currentOrganization) {
    if (!oidcConfig.token_request_extras) {
      oidcConfig.token_request_extras = {};
    }
    oidcConfig.token_request_extras.current_org = currentOrganization;
    clearCurrentOrganization();
  }

  return oidcConfig;
}
