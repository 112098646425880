import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { type CreateDataRequestDto, DataRelatedToDataset, usePostDataRequest } from '#edsn/api/pie-bff';
import { DataServicesFormStep1 } from './step-1/DataServicesFormStep1';
import { DataServicesFormStep2 } from './step-2/DataServicesFormStep2';
import { DataServicesFormStep3 } from './step-3/DataServicesFormStep3';
import type { SubmitHandler } from 'react-hook-form';
import { Button } from '#pie/components/button/Button';
import { CancelDataServicesRequest } from '#pie/components/cancel-data-services-request/CancelDataServicesRequest';
import { Card } from '#pie/components/card/Card';
import { Page, PageHeader } from '#pie/components/page/Page';
import { Stack } from '#pie/components/stack/Stack';
import { Stepper } from '#pie/components/stepper/Stepper';
import { useToast } from '#pie/components/toast/ToastContext';

export const DataServicesForm = () => {
  const { t: tcom } = useTranslation();
  const { t: tdr } = useTranslation('dataRequest');
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [step, setStep] = useState(0);
  const formHelpers = useForm<CreateDataRequestDto>();
  const { watch } = formHelpers;
  const dataRelatedToDataset = watch('dataRelatedToDataset');
  const [shouldBlock, setShouldBlock] = useState(true);

  const { mutateAsync, isSuccess } = usePostDataRequest({
    mutation: {
      onError: () => {
        addToast({
          message: tdr('toast.error.message'),
          title: tdr('toast.error.title'),
          type: 'error',
        });
      },
      onSuccess: () => {
        setShouldBlock(false);
        addToast({
          message: tdr('toast.success.message'),
          title: tdr('toast.success.title'),
          type: 'success',
        });
      },
    },
  });

  useEffect(() => {
    if (isSuccess) navigate('/datadiensten');
  }, [isSuccess]);

  const Step = [DataServicesFormStep1, DataServicesFormStep2, DataServicesFormStep3][step];

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit: SubmitHandler<CreateDataRequestDto> = async data => {
    if (step === 2) {
      setIsSubmitting(true);
      try {
        await mutateAsync({ data });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setStep(step + 1);
      if (
        dataRelatedToDataset === DataRelatedToDataset.NoEnergyNeighbourScan ||
        dataRelatedToDataset === DataRelatedToDataset.Yes
      ) {
        setStep(2);
      }
    }
  };

  const setStepBack =
    step !== 0
      ? () => {
          if (
            dataRelatedToDataset === DataRelatedToDataset.NoEnergyNeighbourScan ||
            dataRelatedToDataset === DataRelatedToDataset.Yes
          ) {
            setStep(0);
          } else {
            setStep(step - 1);
          }
        }
      : undefined;

  return (
    <Page>
      <PageHeader
        title={tdr('heading')}
        backOnClick={setStepBack}
        details={
          <div className="-mx-6 mt-4 flex justify-center border-t border-t-neutral-200 px-6 pt-3">
            <Stepper
              steps={[
                {
                  children: tdr('step-one-your-datarequest'),
                },
                {
                  children: tdr('step-two-assessment-framework'),
                },
                {
                  children: tdr('step-three-submit-application'),
                },
              ].map((s, index) => ({
                ...s,
                active: index === step,
                disabled:
                  (dataRelatedToDataset === DataRelatedToDataset.NoEnergyNeighbourScan ||
                    dataRelatedToDataset === DataRelatedToDataset.Yes) &&
                  index === 1,
                done: index < step,
                onClick: index < step ? () => setStep(index) : undefined,
              }))}
            />
          </div>
        }
      />
      <FormProvider {...formHelpers}>
        <form onSubmit={formHelpers.handleSubmit(onSubmit)}>
          <Card size="md" shadow={false} className="mx-auto max-w-4xl">
            <Stack gap="lg" divider="line">
              <Step />
              <footer className="flex justify-between gap-x-4">
                <Button as={Link} to=".." variant="ghost">
                  {tcom('common.button.cancel')}
                </Button>
                <Button isLoading={isSubmitting} variant="secondary" type="submit">
                  {step === 2 ? tdr('submit') : tcom('common.steps.next')}
                </Button>
              </footer>
            </Stack>
          </Card>
          <CancelDataServicesRequest shouldBlock={shouldBlock} />
        </form>
      </FormProvider>
    </Page>
  );
};
