import { useTranslation } from 'react-i18next';
import { type CreateDataRequestDto, DataRelatedToDataset, DataType } from '#edsn/api/pie-bff';
import type { FormCheckboxType } from '#pie/components/form/types';
import { Stack } from '#pie/components/stack/Stack';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { FormCheckbox, ConditionalField } = typedFormFields<CreateDataRequestDto>();

export const DataTypes = () => {
  //Welk type data wil je ontvangen?
  const { t } = useTranslation('dataRequest');
  const options: FormCheckboxType[] = [
    {
      label: t(`data_type.${DataType.Asset}`),
      tooltip: t(`data_type.tooltip.${DataType.Asset}`),
      value: DataType.Asset,
    },
    {
      label: t(`data_type.${DataType.Consumption}`),
      tooltip: t(`data_type.tooltip.${DataType.Consumption}`),
      value: DataType.Consumption,
    },
    {
      label: t(`data_type.${DataType.KeyFigures}`),
      value: DataType.KeyFigures,
    },
  ];

  return (
    <ConditionalField
      field="dataTypes"
      condition={formValue => {
        return (
          !!formValue('dataRequestTo') &&
          formValue('dataRequestTo')!.length > 0 &&
          formValue('dataRelatedToDataset') === DataRelatedToDataset.No
        );
      }}
    >
      <Stack direction="column" gap="sm">
        <FormCheckbox name="dataTypes" label={t('data_types')} options={options} rules={{ required: true }} />
      </Stack>
    </ConditionalField>
  );
};
