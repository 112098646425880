import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { screenSizes, useScreenSize } from './useScreenSize';

export const useSidebar = () => {
  const screenSize = useScreenSize();
  const [isOpen, setIsOpen] = useState<boolean | undefined>();
  const [forceOpen, setForceOpen] = useState<boolean | undefined>();
  const { pathname } = useLocation();

  /* c8 ignore start */
  useEffect(() => {
    if (!screenSize) return;

    if (screenSizes.indexOf(screenSize) > 3) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [screenSize, pathname]);
  /* c8 ignore end */

  return { isOpen: forceOpen === undefined ? isOpen : forceOpen, setIsOpen: setForceOpen };
};
