import { useTranslation } from 'react-i18next';
import { Answer, type CreateDataRequestDto, SimpleAnswer } from '#edsn/api/pie-bff';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { FormRadio, ConditionalField } = typedFormFields<CreateDataRequestDto>();

export const PersonalInformation = () => {
  const { t } = useTranslation('dataRequest');

  const yesNoOptions = [
    {
      label: t(`simple_answer.${SimpleAnswer.Yes}`),
      tooltip: t('information_is_personal_data.yes.tooltip'),
      value: SimpleAnswer.Yes,
    },
    {
      label: t(`simple_answer.${SimpleAnswer.No}`),
      value: SimpleAnswer.No,
    },
  ];

  return (
    <ConditionalField
      field="informationIsPersonalData"
      condition={formValue => {
        return (
          formValue('legalObligation') === Answer.No ||
          formValue('legalObligation') === Answer.DontKnow ||
          !!formValue('legalObligationReason')
        );
      }}
    >
      <FormRadio
        name="informationIsPersonalData"
        infoTooltip={t('information_is_personal_data.tooltip')}
        label={t('information_is_personal_data')}
        options={yesNoOptions}
        rules={{ required: true }}
      />
    </ConditionalField>
  );
};
