import infoIcon from '@iconify/icons-material-symbols/info-outline';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Answer, type CreateDataRequestDto } from '#edsn/api/pie-bff';
import { Button } from '#pie/components/button/Button';
import { Message } from '#pie/components/message/Message';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const PersonalDataLegalBasis = () => {
  const { t } = useTranslation('dataRequest');
  const { FormRadio, ConditionalField } = typedFormFields<CreateDataRequestDto>();
  const { watch } = useFormContext<CreateDataRequestDto>();
  const noLegalBasis = watch('legalBasisPersonalData') === Answer.No;

  const YesNoDontKnowOptions = [
    {
      label: t(`answer.${Answer.Yes}`),
      value: Answer.Yes,
    },
    {
      label: t(`answer.${Answer.No}`),
      value: Answer.No,
    },
    {
      label: t(`answer.${Answer.DontKnow}`),
      value: Answer.DontKnow,
    },
  ];

  return (
    <>
      <ConditionalField
        field="legalBasisPersonalData"
        condition={formValue => formValue('informationIsPersonalData') === Answer.Yes}
      >
        <FormRadio
          name="legalBasisPersonalData"
          label={t('legal_basis_personal_data')}
          options={YesNoDontKnowOptions}
          rules={{ required: true }}
        />
      </ConditionalField>

      {noLegalBasis && (
        <>
          <Message variant="warning" icon={infoIcon}>
            {t('legal_basis_personal_data.no_basis_explainer')}
          </Message>
          <Button as={Link} variant="secondary" to="/datadiensten">
            {t('legal_basis_personal_data.end_request')}
          </Button>
        </>
      )}
    </>
  );
};
