import { useTranslation } from 'react-i18next';
import type { FormCheckboxType } from '#pie/components/form/types';
import { CraftAssetDownload } from '#pie/components/craft-asset-download/CraftAssetDownload';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const DataServicesFormStep3 = () => {
  const { t } = useTranslation('dataRequest');
  const { FormCheckbox } = typedFormFields();
  const options: FormCheckboxType[] = [{ label: 'Ja, akkoord', value: 'yes' }];

  return (
    <Stack gap="lg">
      <Stack gap="sm">
        <Text variant="h6" as="h2">
          {t('consent_statement')}
        </Text>
        <Text>
          {t('general_terms_and_conditions_data_usage')}{' '}
          <CraftAssetDownload title="Uitgangspunten en voorwaarden datalevering">
            algemene voorwaarden
          </CraftAssetDownload>
        </Text>
      </Stack>
      <Stack gap="sm">
        <Text variant="h6" as="h2">
          {t('general_terms_and_conditions_accept')}
        </Text>
        <FormCheckbox options={options} name="agreement" rules={{ required: true }} />
      </Stack>
    </Stack>
  );
};
