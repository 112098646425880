import addIcon from '@iconify/icons-material-symbols/add';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { CreateSubcategoryNotificationDto } from '#edsn/api/pie-bff';
import { getGetNotificationQueryKey, useGetNotification, usePostNotificationSubCategory } from '#edsn/api/pie-bff';
import { TicketCategoriesDropdowns } from '../ticket-category-dropdowns/TicketCategoryDropdowns';
import type { SubmitHandler } from 'react-hook-form';
import { Button } from '#pie/components/button/Button';
import { Dialog } from '#pie/components/layout/dialog/Dialog';
import { Stack } from '#pie/components/stack/Stack';
import { useToast } from '#pie/components/toast/ToastContext';
import { typedFormFields } from '#pie/utils/typedFormFields';

interface Form extends Omit<CreateSubcategoryNotificationDto, 'email'> {
  categoryEmail: string;
}

const { FormText } = typedFormFields<Form>();

export const AddNotificationButton = () => {
  const { addToast } = useToast();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const { data, isLoading } = useGetNotification();
  const { t: tcom } = useTranslation();
  const { t: tom } = useTranslation('organisationManagement');
  const queryClient = useQueryClient();
  const formMethods = useForm<Form>();

  const { mutate, isLoading: isMutating } = usePostNotificationSubCategory({
    mutation: {
      onError: () => {
        addToast({
          message: `${tom('toast.error.message_add_exception')}`,
          title: `${tom('toast.error.title_add_exception')}`,
          type: 'error',
        });
      },
      onSuccess: () => {
        gtag('event', 'add_notifation_category', {});
        addToast({
          message: `${tom('toast.success.message_add_exception')}`,
          title: `${tom('toast.success.title_add_exception')}`,
          type: 'success',
        });
        setOpenAddDialog(false);
        queryClient.removeQueries(getGetNotificationQueryKey());
        queryClient.removeQueries({
          queryKey: ['/notification'],
        });
      },
    },
  });

  const onSubmit: SubmitHandler<Form> = data => {
    mutate({
      data: {
        email: data.categoryEmail,
        subcategoryId: data.subcategoryId,
      },
    });
  };

  return (
    <>
      <Button variant="ghost" iconStart={addIcon} className="self-start" onClick={() => setOpenAddDialog(true)}>
        {tom('add_exception')}
      </Button>
      <Dialog title={tom('add_exception')} open={openAddDialog} onOpenChange={setOpenAddDialog}>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Stack gap="lg">
              <TicketCategoriesDropdowns
                excludedSubcategories={data?.subcategories.map(cat => cat.subcategoryId)}
                isLoading={isLoading}
              />
              <FormText
                name="categoryEmail"
                label={tcom('common.email')}
                placeholder={tom('add.email.placeholder')}
                fullWidth
                rules={{ required: true }}
              />
              <Button isLoading={isMutating || isLoading} variant="secondary" type="submit">
                {tom('add_exception')}
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};
