const key = 'currentOrganization';

export function storeCurrentOrganization(organizationId: string) {
  localStorage.setItem(
    key,
    JSON.stringify({
      date: Date.now(),
      organizationId,
    })
  );
}

export function getCurrentOrganization() {
  const item = localStorage.getItem(key);

  if (!item) {
    return undefined;
  }

  const { organizationId, date } = JSON.parse(item);

  // expiration time is 10 minutes

  if (Date.now() - date > 10 * 60 * 1000) {
    localStorage.removeItem(key);
    return undefined;
  }

  return organizationId;
}

export function clearCurrentOrganization() {
  localStorage.removeItem(key);
}
