import IconDelete from '@iconify/icons-material-symbols/delete-outline';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getGetNotificationQueryKey, useDeleteNotificationSubCategoryId } from '#edsn/api/pie-bff';
import type { NotificationSubcategoryDto } from '#edsn/api/pie-bff';
import { Button } from '#pie/components/button/Button';
import { Dialog } from '#pie/components/layout/dialog/Dialog';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { useToast } from '#pie/components/toast/ToastContext';

interface RemoveNotificationButtonProps {
  data: NotificationSubcategoryDto;
}

export const RemoveNotificationButton = ({ data }: RemoveNotificationButtonProps) => {
  const { addToast } = useToast();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { t: tom } = useTranslation('organisationManagement');
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useDeleteNotificationSubCategoryId({
    mutation: {
      onError: () => {
        addToast({
          message: `${tom('toast.error.message_adjusting_email')}`,
          title: `${tom('toast.error.title.delete_notification')}`,
          type: 'error',
        });
      },
      onSuccess: () => {
        gtag('event', 'delete_notification_category', {});
        addToast({
          message: `${tom('toast.success.message_notification_deleted', {
            name: data.subcategoryName,
          })}`,
          title: `${tom('toast.success.title.delete_notification')}`,
          type: 'success',
        });
        setOpenDeleteDialog(false);
        queryClient.invalidateQueries({
          predicate: m => {
            return m.queryKey.some(n => typeof n === 'string' && n.includes(data.id));
          },
        });
        queryClient.invalidateQueries(getGetNotificationQueryKey());
        queryClient.removeQueries({
          queryKey: ['/notification'],
        });
      },
    },
  });

  return (
    <>
      <Button
        iconStart={IconDelete}
        variant="link"
        onClick={() => setOpenDeleteDialog(true)}
        className="ml-auto"
        aria-label={`delete-notification-${data.subcategoryName}`}
      />
      <Dialog title={tom('title.delete_notification')} open={openDeleteDialog} onOpenChange={setOpenDeleteDialog}>
        <Stack gap="lg">
          <Text>{tom('delete.message_notification', { name: data.subcategoryName })}</Text>
          <div className="grid-auto grid auto-cols-fr grid-flow-col gap-2">
            <Button
              variant="ghost"
              onClick={() => {
                setOpenDeleteDialog(false);
              }}
            >
              {tom('delete.button.cancel_keep_exception')}
            </Button>
            <Button isLoading={isLoading} variant="danger" onClick={() => mutate({ id: data.id })}>
              {tom('delete.button.delete_remove_exception')}
            </Button>
          </div>
        </Stack>
      </Dialog>
    </>
  );
};
