import { useTranslation } from 'react-i18next';
import {
  AssetType,
  ConsumptionType,
  type CreateDataRequestDto,
  DataRelatedToDataset,
  DataType,
} from '#edsn/api/pie-bff';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { ConditionalField, FormTextArea } = typedFormFields<CreateDataRequestDto>();

export const DataTypeNote = () => {
  const { t } = useTranslation('dataRequest');

  return (
    <ConditionalField
      field="dataTypeNote"
      condition={formvalue => {
        const dataTypes = formvalue('dataTypes');
        const relatedData = formvalue('dataRelatedToDataset');
        const isAssetType = !!dataTypes?.includes(DataType.Asset);
        const isConsumptionType = !!dataTypes?.includes(DataType.Consumption);

        if (isAssetType) {
          const assetTypes = formvalue('assetTypes');
          const assetTypesNote = formvalue('assetTypeNote');
          if (
            !assetTypes ||
            !assetTypes.length ||
            (assetTypes.includes(AssetType.Other) && (!assetTypesNote || assetTypesNote.length < 2))
          ) {
            return false;
          }
        }

        if (isConsumptionType) {
          const consumptionTypes = formvalue('consumptionTypes');
          const consumptionTypeNote = formvalue('consumptionTypeNote');

          if (
            !consumptionTypes ||
            !consumptionTypes.length ||
            (consumptionTypes.includes(ConsumptionType.Other) &&
              (!consumptionTypeNote || consumptionTypeNote.length < 2))
          ) {
            return false;
          }
        }

        return (!!dataTypes && dataTypes.length > 0) || relatedData === DataRelatedToDataset.NoEnergyNeighbourScan;
      }}
    >
      <FormTextArea
        name="dataTypeNote"
        label={t('data_type_note')}
        fullWidth
        showWordCount
        rules={{ maxLength: 2000, minLength: 2, required: true }}
      />
    </ConditionalField>
  );
};
