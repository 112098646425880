import addIcon from '@iconify/icons-material-symbols/add';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useExtendedRules } from '../formHelpers';
import type { ComponentProps } from 'react';
import type { FieldValues, UseControllerProps } from 'react-hook-form';
import { FileAnchor } from '#pie/components/file-anchor/FileAnchor';
import { InputField } from '#pie/components/input-field/InputField';
import { InputUploadButton } from '#pie/components/input-upload-button/InputUploadButton';
import { MAX_FILE_SIZE } from '#pie/constants/maxFileSize';
import { formatBytes } from '#pie/utils/files';

interface Props<T extends FieldValues>
  extends Pick<UseControllerProps<T>, 'disabled' | 'name' | 'rules' | 'defaultValue'>,
    Pick<ComponentProps<typeof InputUploadButton>, 'multiple' | 'className' | 'acceptedFiles'> {
  label: string;
}

const isFile = (value: unknown): value is File => (value as File)?.name !== undefined;

export const FormFile = <T extends FieldValues>({ name, label, rules: originalRules }: Props<T>) => {
  const { t } = useTranslation();
  const rules = useExtendedRules({
    validate: value => {
      /* c8 ignore next */
      if (!isFile(value)) return false;

      const file = value as File;
      if (file.size > MAX_FILE_SIZE) return t('zod.errors.file_too_large', { size: formatBytes(MAX_FILE_SIZE) });
    },
    ...originalRules,
  });
  const { resetField } = useFormContext<T>();

  const {
    fieldState: { error },
    field: { value, onChange },
  } = useController<T>({
    name,
    rules,
  });

  return (
    <InputField isRequired={!!rules?.required} name={name} label={label} error={error?.message}>
      {value && (
        <FileAnchor
          fileName={value.name}
          fileSize={value.size}
          onDelete={() => {
            resetField(name);
          }}
        />
      )}
      <InputUploadButton
        icon={addIcon}
        variant="ghost"
        className="w-fit"
        multiple
        onChange={value => {
          onChange(value[0] as unknown);
        }}
      >
        {t('common.attachments.choose_files_one')}
      </InputUploadButton>
    </InputField>
  );
};
