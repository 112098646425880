import { useTranslation } from 'react-i18next';
import { AssetType, type CreateDataRequestDto } from '#edsn/api/pie-bff';
import type { FormCheckboxType } from '#pie/components/form/types';
import { Stack } from '#pie/components/stack/Stack';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { FormCheckbox, ConditionalField, FormText } = typedFormFields<CreateDataRequestDto>();

export const AssetTypes = () => {
  const { t } = useTranslation('dataRequest');

  const options: FormCheckboxType[] = [
    {
      label: t(`asset_type.${AssetType.CablesOrPipes}`),
      value: AssetType.CablesOrPipes,
    },
    {
      label: t(`asset_type.${AssetType.Connections}`),
      value: AssetType.Connections,
    },
    {
      label: t(`asset_type.${AssetType.InfluenceRequests}`),
      tooltip: t(`asset_type.tooltip.${AssetType.InfluenceRequests}`),
      value: AssetType.InfluenceRequests,
    },
    {
      label: t(`asset_type.${AssetType.Other}`),
      renderInput: (
        <ConditionalField
          field="assetTypeNote"
          condition={formValue => {
            return !!formValue('assetTypes') && formValue('assetTypes')!.includes(AssetType.Other);
          }}
        >
          <FormText
            aria-label={t('asset_type_note')}
            name="assetTypeNote"
            fullWidth
            rules={{ maxLength: 100, minLength: 2, required: true }}
          />
        </ConditionalField>
      ),
      value: AssetType.Other,
    },
  ];

  return (
    <ConditionalField
      field="assetTypes"
      condition={formValue => {
        return !!formValue('energyTypes') && formValue('energyTypes')!.length > 0;
      }}
    >
      <Stack direction="column" gap="sm">
        <FormCheckbox name="assetTypes" label={t('asset_types')} options={options} rules={{ required: true }} />
      </Stack>
    </ConditionalField>
  );
};
