import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { CreateDataRequestDto } from '#edsn/api/pie-bff';
import { DataLevel, DataLevelZipCode, DataType, FileFormat } from '#edsn/api/pie-bff';
import type { FormCheckboxType } from '#pie/components/form/types';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { ConditionalField, FormCheckbox, FormText } = typedFormFields<CreateDataRequestDto>();

export const FileFormats = () => {
  const { t } = useTranslation('dataRequest');
  const options: FormCheckboxType[] = [
    {
      label: t(`file_formats.${FileFormat.Csv}`),
      value: FileFormat.Csv,
    },
    {
      label: t(`file_formats.${FileFormat.Shape}`),
      value: FileFormat.Shape,
    },
    {
      label: t(`file_formats.${FileFormat.Other}`),
      renderInput: (
        <ConditionalField
          field="fileFormatNote"
          condition={fieldValue => !!fieldValue('fileFormats')?.includes(FileFormat.Other)}
        >
          <FormText
            aria-label={t('file_format_note')}
            name="fileFormatNote"
            fullWidth
            showWordCount
            rules={{ maxLength: 25, minLength: 2, required: true }}
          />
        </ConditionalField>
      ),
      value: FileFormat.Other,
    },
  ];
  const { watch } = useFormContext<CreateDataRequestDto>();

  return (
    <ConditionalField
      field="fileFormats"
      condition={formValue => {
        if (formValue('partitionLevelNote') && formValue('partitionLevelNote')!.length > 1) return true;
        if (formValue('partitionLevel') === DataLevel.Address && formValue('partitionFile')) return true;
        if (
          formValue('partitionLevelZipCode') &&
          formValue('partitionLevelZipCode') !== DataLevelZipCode.Other &&
          formValue('partitionFile')
        )
          return true;
        if (
          formValue('partitionLevelZipCodeNote') &&
          formValue('partitionLevelZipCodeNote')!.length > 1 &&
          formValue('partitionFile')
        )
          return true;
        if (
          formValue('partitionLevel') &&
          formValue('partitionLevel') !== DataLevel.ZipCode &&
          formValue('partitionLevel') !== DataLevel.Address
        )
          return true;
        return false;
      }}
    >
      <FormCheckbox
        label={t('file_formats')}
        name="fileFormats"
        options={options.filter(
          o => !((o.value as FileFormat) === FileFormat.Shape && watch('dataTypes')?.includes(DataType.Consumption))
        )}
        rules={{ required: true }}
      />
    </ConditionalField>
  );
};
