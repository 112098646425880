import { useTranslation } from 'react-i18next';
import { Card } from '#pie/components/card/Card';
import { NavigationTile } from '#pie/components/navigation-tile/NavigationTile';
import { NewsCard } from '#pie/components/news-card/NewsCard';
import { Page } from '#pie/components/page/Page';
import { Skeleton } from '#pie/components/skeleton/Skeleton';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { useProtectedMenu } from '#pie/hooks/useProtectedMenu';
import { screenSizes, useScreenSize } from '#pie/hooks/useScreenSize';
import { useUserInfo } from '#pie/hooks/useUserInfo';
import { trpc } from '#pie/trpc';
import { getTagList } from '#pie/utils/tags';

export function Home() {
  const { t } = useTranslation();
  const [{ data: userInfo, isLoading }] = useUserInfo();
  const name = userInfo && `${userInfo.given_name} ${userInfo.family_name}`;

  const { mainMenu } = useProtectedMenu();

  const { data: news, isLoading: isNewsLoading } = trpc.pages.news.recentItems.useQuery(
    { limit: 5 },
    { keepPreviousData: true }
  );

  const currentSize = useScreenSize();
  // TODO: Write tests for this
  /* c8 ignore start */
  const shownNews = news?.slice(
    0,
    currentSize && screenSizes.indexOf(currentSize) > screenSizes.indexOf('2xl') ? 5 : 4
  );
  /* c8 ignore end */

  return (
    <Page>
      <Stack gap="lg" className="py-6">
        <Stack as="section" gap="lg">
          <Text variant="h1" as="h1">
            {!isLoading ? t('home.header.title', { name }) : <Skeleton className="max-w-xl" />}
          </Text>
          <Card className="p-6">
            <Stack gap="lg">
              <Text variant="h4" as="h2">
                {t('home.pages.title')}
              </Text>
              <div className="grid grid-cols-[repeat(auto-fill,_minmax(18rem,_1fr))] gap-6">
                {[...mainMenu].map(menuItem => (
                  <NavigationTile key={`navtile-${menuItem.to}`} {...menuItem} />
                ))}
              </div>
            </Stack>
          </Card>
        </Stack>
        {(isNewsLoading || (shownNews && shownNews.length > 0)) && (
          <Stack as="section" gap="lg">
            <Text variant="h4" as="h2">
              {t('common.news.title')}
            </Text>
            <div className="grid grid-cols-[repeat(auto-fill,_minmax(22rem,_1fr))] gap-6 xl:grid-flow-col xl:grid-cols-[auto]">
              {isNewsLoading
                ? Array(3)
                    .fill(null)
                    .map((_, i) => <NewsCard as="div" isLoading key={`news-${i}`} />)
                : shownNews?.map(newsItem => {
                    /* c8 ignore next 1 */
                    const hashTags = newsItem.tags ? getTagList(newsItem.tags, 3) : undefined;
                    return (
                      <NewsCard
                        key={newsItem.id}
                        to={newsItem.uri}
                        title={newsItem.title}
                        description={newsItem.excerpt}
                        imageUrl={newsItem.image.url}
                        date={newsItem.postDate}
                        categories={hashTags}
                      />
                    );
                  })}
            </div>
          </Stack>
        )}
      </Stack>
    </Page>
  );
}
