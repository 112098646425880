import { useTranslation } from 'react-i18next';
import type { FieldPath, FieldValues, RegisterOptions } from 'react-hook-form';

type RulesType<T extends FieldValues> = Omit<
  RegisterOptions<T, FieldPath<T>>,
  'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
>;
export function useExtendedRules<T extends FieldValues>(rules?: RulesType<T>): RulesType<T> | undefined {
  const { t } = useTranslation();

  return rules
    ? {
        ...rules,
        maxLength:
          typeof rules.maxLength === 'number'
            ? { message: t('common.errors.max_length', { char: rules.maxLength }), value: rules.maxLength }
            : rules.maxLength,
        minLength:
          typeof rules.minLength === 'number'
            ? { message: t('common.errors.min_length', { char: rules.minLength }), value: rules.minLength }
            : rules.minLength,
        required: rules.required === true ? t('zod.errors.required') : rules.required,
      }
    : undefined;
}
