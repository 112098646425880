import IconDelete from '@iconify/icons-material-symbols/delete-outline';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getGetTeamIdQueryKey, useDeleteContactDetailsContactIdTeamTeamId } from '#edsn/api/pie-bff';
import type { ContactDto } from '#edsn/api/pie-bff';
import { Button } from '#pie/components/button/Button';
import { Dialog } from '#pie/components/layout/dialog/Dialog';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { useToast } from '#pie/components/toast/ToastContext';

interface RemoveTeamMemberButtonProps {
  data: ContactDto;
  teamId: string;
}

export const RemoveTeamMemberButton = ({ data, teamId }: RemoveTeamMemberButtonProps) => {
  const { addToast } = useToast();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { t: tcom } = useTranslation();
  const { t: tom } = useTranslation('organisationManagement');
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useDeleteContactDetailsContactIdTeamTeamId({
    mutation: {
      onError: () => {
        addToast({
          message: `${tom('error.message.delete_user')}`,
          title: `${tcom('common.errors.delete_user')}`,
          type: 'error',
        });
      },
      onSuccess: () => {
        gtag('event', 'delete_team_member', {});
        addToast({
          message: `${tom('success.message.delete_user', { name: data.fullName })}`,
          title: `${tcom('common.deleted_user')}`,
          type: 'success',
        });
        setOpenDeleteDialog(false);
        queryClient.invalidateQueries({
          predicate: m => {
            return m.queryKey.some(n => typeof n === 'string' && n.includes(data.id));
          },
        });
        queryClient.invalidateQueries(getGetTeamIdQueryKey(teamId));
        queryClient.removeQueries({
          queryKey: ['/contact/details'],
        });
      },
    },
  });

  return (
    <>
      <Button
        iconStart={IconDelete}
        variant="link"
        onClick={() => setOpenDeleteDialog(true)}
        className="ml-auto"
        aria-label="delete-team-member"
      />
      <Dialog title={tom('delete.title.delete_user')} open={openDeleteDialog} onOpenChange={setOpenDeleteDialog}>
        <Stack gap="lg">
          <Text>{tom('delete.message.delete_user', { name: data.fullName })}</Text>
          <div className="grid-auto grid auto-cols-fr grid-flow-col gap-2">
            <Button
              variant="ghost"
              onClick={() => {
                setOpenDeleteDialog(false);
              }}
            >
              {tom('delete.button.cancel.keep_user')}
            </Button>
            <Button
              isLoading={isLoading}
              variant="danger"
              onClick={() => mutate({ contactId: data.id, teamId: teamId })}
            >
              {tcom('common.delete_user')}
            </Button>
          </div>
        </Stack>
      </Dialog>
    </>
  );
};
