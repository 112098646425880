import { useTranslation } from 'react-i18next';
import { Answer, type CreateDataRequestDto, LegalBasisPersonalDataReason } from '#edsn/api/pie-bff';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const PersonalDataLegalBasisReason = () => {
  const { t } = useTranslation('dataRequest');
  const { FormText, FormRadio, ConditionalField } = typedFormFields<CreateDataRequestDto>();

  const legalBasisPersonalDataReasonOptions = [
    {
      label: t(`legal_basis_personal_data_reason.${LegalBasisPersonalDataReason.SignedCustomerMandate}`),
      value: LegalBasisPersonalDataReason.SignedCustomerMandate,
    },
    {
      label: t(`legal_basis_personal_data_reason.${LegalBasisPersonalDataReason.LegalObligation}`),
      value: LegalBasisPersonalDataReason.LegalObligation,
    },
    {
      label: t(`legal_basis_personal_data_reason.${LegalBasisPersonalDataReason.PublicTask}`),
      value: LegalBasisPersonalDataReason.PublicTask,
    },
    {
      label: t(`legal_basis_personal_data_reason.${LegalBasisPersonalDataReason.LegitimateInterest}`),
      value: LegalBasisPersonalDataReason.LegitimateInterest,
    },
    {
      label: t(`legal_basis_personal_data_reason.${LegalBasisPersonalDataReason.Other}`),
      renderInput: (
        <ConditionalField
          field="legalBasisPersonalDataReasonNote"
          condition={formValue => formValue('legalBasisPersonalDataReason') === LegalBasisPersonalDataReason.Other}
        >
          <FormText
            aria-label={t('legal_basis_personal_data_reason.note')}
            name={'legalBasisPersonalDataReasonNote'}
            fullWidth
            showWordCount
            rules={{ maxLength: 25, minLength: 2, required: true }}
          />
        </ConditionalField>
      ),
      value: LegalBasisPersonalDataReason.Other,
    },
  ];

  return (
    <ConditionalField
      field="legalBasisPersonalDataReason"
      condition={formValue => formValue('legalBasisPersonalData') === Answer.Yes}
    >
      <FormRadio
        name="legalBasisPersonalDataReason"
        label={t('legal_basis_personal_data_reason')}
        options={legalBasisPersonalDataReasonOptions}
        rules={{ required: true }}
      />
    </ConditionalField>
  );
};
