import { useTranslation } from 'react-i18next';
import type { CreateDataRequestDto } from '#edsn/api/pie-bff';
import { DataLevel, DataLevelZipCode } from '#edsn/api/pie-bff';
import type { Option } from '#pie/components/input-radio/InputRadio';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { ConditionalField, FormRadio, FormText, FormFile } = typedFormFields<CreateDataRequestDto>();

export const LevelZipCode = () => {
  const { t } = useTranslation('dataRequest');
  const options: Option[] = [
    { label: t(`zip_code_level.${DataLevelZipCode.ZipCode4}`), value: DataLevelZipCode.ZipCode4 },
    { label: t(`zip_code_level.${DataLevelZipCode.ZipCode6}`), value: DataLevelZipCode.ZipCode6 },
    {
      label: t(`zip_code_level.${DataLevelZipCode.Other}`),
      renderInput: (
        <ConditionalField
          field="levelNote"
          condition={fieldValue => fieldValue('zipCodeLevel') === DataLevelZipCode.Other}
        >
          <FormText
            aria-label={t('level_note')}
            name="levelNote"
            fullWidth
            showWordCount
            rules={{ maxLength: 200, minLength: 2, required: true }}
          />
        </ConditionalField>
      ),
      value: DataLevelZipCode.Other,
    },
  ];
  return (
    <>
      <ConditionalField field="zipCodeFile" condition={fieldValue => fieldValue('level') === DataLevel.ZipCode}>
        <FormFile name="zipCodeFile" label={t('zip_code_level_file')} rules={{ required: true }} />
      </ConditionalField>
      <ConditionalField field="zipCodeLevel" condition={fieldValue => fieldValue('level') === DataLevel.ZipCode}>
        <FormRadio label={t('zip_code_level')} name="zipCodeLevel" options={options} rules={{ required: true }} />
      </ConditionalField>
    </>
  );
};
