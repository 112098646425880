import docIcon from '@iconify/icons-material-symbols/docs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '#pie/components/button/Button';
import { Card } from '#pie/components/card/Card';
import { Page, PageHeader } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';

export const DataServices = () => {
  const { t } = useTranslation('dataRequest');

  return (
    <>
      <PageHead title={t('title')} />
      <Page>
        <PageHeader title={t('title')} className="mb-8">
          <Button as={Link} to="/datadiensten/verzoeken" variant="secondary">
            {t('start.request')}
          </Button>
        </PageHeader>
        <Stack gap="lg" className="mx-auto my-6 max-w-4xl">
          <Card
            size="md"
            heading={
              <Text as="h2" variant="h5">
                Aanvragen van Maatwerkdata
              </Text>
            }
          >
            <Stack gap="lg">
              <Text>
                De regionale netbeheerders beheren data over de elektriciteits- en/of gasnetten van Nederland. Dit zijn
                gegevens over bijvoorbeeld het energieverbruik van aansluitingen in een bepaalde straat of de ligging
                van kabels, leidingen en stations in een bepaalde gemeente. Deze informatie helpt je onder andere bij
                het maken van keuzes en de uitleg hiervan. Ook kan het je ondersteunen bij het plannen en uitvoeren van
                werkzaamheden met grondroering.
              </Text>

              <Stack gap="sm">
                <Text as="h2" variant="h6">
                  Open data
                </Text>
                <Text>
                  De regionale netbeheerders stellen sommige data als open data beschikbaar. Dit betekent dat die data
                  gratis en voor iedereen toegankelijk is. Via de websites van de regionale netbeheerders is te zien wat
                  er al beschikbaar is. Hieronder vind je een verwijzing naar de decentrale websites:
                </Text>

                <Button
                  as="a"
                  variant="linkPrimary"
                  href="https://www.liander.nl/partners/datadiensten/open-data/data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Liander
                </Button>
                <Button
                  as="a"
                  variant="linkPrimary"
                  href="https://www.stedin.net/zakelijk/open-data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stedin
                </Button>
                <Button
                  as="a"
                  variant="linkPrimary"
                  href="https://www.rendonetwerken.nl/open-data/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rendo
                </Button>
                <Button
                  as="a"
                  variant="linkPrimary"
                  href="https://coteqnetbeheer.nl/open-data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Coteq
                </Button>
                <Button
                  as="a"
                  variant="linkPrimary"
                  href="https://www.westlandinfra.nl/over-westland-infra/duurzaamheid-innovaties/open-data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Westland Infra
                </Button>
                {/* </Stack> */}
              </Stack>
              <Stack gap="sm">
                <Text variant="h6">Maatwerkverzoek</Text>
                <Text>
                  Als je data wilt ontvangen die nog niet online beschikbaar is, kun je een maatwerk dataverzoek
                  indienen. Hieronder staat beschreven welke stappen je hiervoor moet doorlopen. Wil je een Klic-melding
                  doen? Ga dan naar{' '}
                  <a
                    href="https://www.kadaster.nl/producten/woning/klic-melding?gclid=Cj0KCQjwu-KiBhCsARIsAPztUF1QUPddAhSB7S-M2h0hl8wgW54MggyxJgNoUjvZJGk_7NdkftsagycaAjG1EALw_wcB"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary underline"
                  >
                    KLIC-melding doen particulier: meld uw graafwerkzaamheden. - Kadaster.nl particulier
                  </a>
                </Text>
              </Stack>

              <Stack gap="sm">
                <Text variant="h6">Stappen bij het indienen van een maatwerk dataverzoek</Text>
                <Stack as="ol" gap="sm" className="ml-6 list-decimal">
                  <li className="text-primary-dark font-bold">
                    <Text className="text-primary-dark font-bold">
                      Bekijk onze beschikbare open data en datadiensten
                    </Text>
                    <Text className="text-neutral-600">
                      Het is mogelijk dat we de gewenste gegevens al aanbieden als open data of via één van de andere
                      datadiensten van de regionale netbeheerders. Deze standaarddiensten zijn vaak gratis of goedkoper
                      dan maatwerk. Een overzicht van onze beschikbare open data en datadiensten is te zien via{' '}
                      <a
                        href="https://data.overheid.nl/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary underline"
                      >
                        data.overheid.nl
                      </a>{' '}
                      of via de websites van de regionale netbeheerders.
                    </Text>
                  </li>
                  <li className="text-primary-dark font-bold">
                    <Text className="text-primary-dark font-bold">Account aanmaken</Text>
                    <Stack gap="sm">
                      <Text className="text-neutral-600">
                        Voor het indienen van een dataverzoek heb je een account op Partners in Energie nodig. Het
                        voordeel hiervan is dat je in de toekomst de status van je verzoek kunt volgen en je opgevraagde
                        datasets op één plek kunt vinden. Ook willen wij in de toekomst ons productaanbod via je account
                        weergeven. Heb je nog geen account? Stuur dan een mail naar{' '}
                        <a href="mailto:servicedesk@edsn.nl" className="text-primary underline">
                          servicedesk@edsn.nl
                        </a>
                        . Voor het aanmaken van een account hebben wij jouw voornaam, achternaam, e-mailadres en
                        telefoonnummer nodig. Indien beschikbaar ontvangen wij ook graag het KVK-nummer van de
                        organisatie waar jij een aanvraag voor indient. Het aanmaken van een account duurt maximaal 5
                        werkdagen.
                      </Text>
                    </Stack>
                  </li>
                  <li className="text-primary-dark font-bold">
                    <Text className="text-primary-dark font-bold">Vul het aanvraagformulier in</Text>
                    <Stack gap="sm">
                      <Text className="text-neutral-600">
                        Het is belangrijk dat wij vooraf informatie ontvangen over je verzoek. Hiervoor zijn in het
                        aanvraagformulier vier stappen met vragen opgesteld, namelijk:
                      </Text>
                      <ul>
                        <li>Stap 1. Jouw dataverzoek</li>
                        <li>Stap 2. Invullen toetsingskader</li>
                        <li>Stap 3. Indienen aanvraag</li>
                      </ul>
                      <Text className="text-neutral-600">
                        Heb je alle vragen ingevuld en is het formulier ingediend? Dan ontvang je van ons een
                        automatische bevestigingsmail. Hierin staat het vervolg van het proces beschreven.
                      </Text>
                      <Text className="text-neutral-600">
                        Voor het leveren van maatwerkdata kunnen wij kosten in rekening brengen. Hier word je vooraf
                        over geïnformeerd.
                      </Text>
                    </Stack>
                  </li>
                </Stack>
              </Stack>
              <Stack gap="sm">
                <Text variant="h6">De Energie-Buurtscan</Text>
                <Text>
                  De Energie-Buurtscan is een dataproduct van Liander en geeft inzicht in het lokale congestieprobleem
                  door het beschikbaar stellen van het schaarste niveau, capaciteit en piekbelasting in specifieke
                  gebieden. De Energie-Buurtscan is beschikbaar voor een selecte groep klanten. Deze groep betreft
                  grootverbruik klanten, door hen gemandateerde adviesbureaus en Congestion Service Providers (CSP’s).
                </Text>

                <Text>
                  Klanten die reeds toegang hebben tot het decentrale dataportaal van de Liander Energie-Buurtscan, en
                  hier een vraag over willen stellen, doen dit vanaf nu via hetzelfde aanvraagformulier.
                </Text>
              </Stack>
              <Stack gap="sm">
                <Text variant="h6">Interesselijst</Text>
                <Text>
                  Nieuw geïnteresseerden voor de Liander Energie-Buurtscan kunnen een mail sturen naar:{' '}
                  <a
                    href="mailto:energiebuurtscan@alliander.com?subject=Toegang tot Energie-Buurtscan"
                    className="text-primary underline"
                  >
                    energiebuurtscan@alliander.com
                  </a>{' '}
                  met daarin de volgende informatie:
                </Text>
                <ul className="ml-6 list-disc">
                  <li>Naam geïnteresseerde GV-klant;</li>
                  <li>Naam van betrokken adviesbureau of CSP gemandateerd door GV-klant (indien van toepassing);</li>
                  <li>Gevraagde locatie(s), zo concreet mogelijk (bijv. Bedrijventerrein ABC);</li>
                  <li>Naam, e-mailadres en telefoonnummer contactpersoon;</li>
                  <li>Waarvoor de partij Energie-Buurtscan wil gebruiken en vanaf wanneer dit gewenst is.</li>
                </ul>
              </Stack>

              <Stack gap="sm">
                <Text variant="h6">Wat doen wij met jouw gegevens?</Text>
                <Text>
                  In het aanvraagproces vragen wij persoonsgegevens zoals jouw e-mailadres en telefoonnummer op. Dit
                  hebben wij nodig om je te bereiken bij vragen over jouw verzoek. Adresgegevens zijn nodig om de juiste
                  informatie te verzamelen voor een passend antwoord. In de{' '}
                  <a href="/privacyverklaring" className="text-primary underline">
                    privacyverklaring
                  </a>{' '}
                  lees je hoe wij met jouw gegevens omgaan.
                </Text>
              </Stack>
              <Stack gap="sm">
                <Text variant="h6">Persoonlijke data</Text>
                <Text>
                  Wil je persoonlijke gegevens van iemand ontvangen? Dan heb je expliciete toestemming nodig van die
                  persoon of personen. Dit kan via een klantmandaat. Hierin moet duidelijk staan welke partijen de
                  gegevens mogen ontvangen en/of gebruiken, het doel, de locatie van vastlegging en de bewaartermijn. In
                  stap 2 van het aanvraagformulier kun je het klantmandaat toevoegen. Heb je geen toestemming? Dan kun
                  je de gegevens wel geclusterd (minimaal 10 adressen) opvragen. In dat geval zijn de personen namelijk
                  niet meer te herleiden.
                </Text>
              </Stack>
              <Button
                as="a"
                href="public/230510 Voorbeeld toestemmingsformulier klantmandaat.docx"
                variant="linkPrimary"
                download
                iconStart={docIcon}
              >
                Voorbeeld toestemmingsformulier klantmandaat.docx
              </Button>
              <Button variant="secondary" as={Link} to="/datadiensten/verzoeken">
                {t('start.request')}
              </Button>
            </Stack>
          </Card>
        </Stack>
      </Page>
    </>
  );
};
