import {
  type ControllerRenderProps,
  type FieldValues,
  type Path,
  type UseControllerProps,
  useController,
} from 'react-hook-form';
import { useExtendedRules } from '../formHelpers';
import type { InputTextProps } from '#pie/components/input-text/InputText';
import type { ComponentProps } from 'react';
import { InputField } from '#pie/components/input-field/InputField';
import { InputText } from '#pie/components/input-text/InputText';

type FormTextProps<T extends FieldValues> = Omit<
  InputTextProps,
  'id' | 'isError' | 'required' | 'minLength' | 'maxLength' | keyof ControllerRenderProps<T, Path<T>>
> &
  Pick<UseControllerProps<T>, 'disabled' | 'name' | 'rules' | 'defaultValue'> &
  Pick<ComponentProps<typeof InputField>, 'label' | 'className'>;

export function FormText<T extends FieldValues>({
  name,
  disabled,
  rules: originalRules,
  label,
  defaultValue,
  ...props
}: FormTextProps<T>) {
  const rules = useExtendedRules(originalRules);
  const {
    fieldState: { error },
    field: { value, ...field },
  } = useController({
    defaultValue,
    disabled,
    name,
    rules,
  });

  return (
    <InputField name={name} isRequired={!!rules?.required} label={label} error={error?.message}>
      <InputText
        id={name}
        isError={!!error}
        disabled={disabled}
        value={value || ''}
        minLength={typeof rules?.minLength === 'object' ? rules.minLength.value : undefined}
        maxLength={typeof rules?.maxLength === 'object' ? rules.maxLength.value : undefined}
        {...field}
        {...props}
      />
    </InputField>
  );
}
