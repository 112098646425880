import { useTranslation } from 'react-i18next';
import { type CreateDataRequestDto, DataRelatedToDataset } from '#edsn/api/pie-bff';
import type { Option } from '#pie/components/input-radio/InputRadio';
import { Button } from '#pie/components/button/Button';
import { Stack } from '#pie/components/stack/Stack';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const RelatedToData = () => {
  const { t } = useTranslation('dataRequest');
  const { FormRadio } = typedFormFields<CreateDataRequestDto>();

  const options: Option[] = [
    {
      label: t(`data_related_to_dataset.${DataRelatedToDataset.Yes}`),
      tooltip: t('data_related_to_dataset.yes.tooltip'),
      value: DataRelatedToDataset.Yes,
    },
    { label: t(`data_related_to_dataset.${DataRelatedToDataset.No}`), value: DataRelatedToDataset.No },
    {
      label: t(`data_related_to_dataset.${DataRelatedToDataset.NoEnergyNeighbourScan}`),
      value: DataRelatedToDataset.NoEnergyNeighbourScan,
    },
  ];

  return (
    <FormRadio
      label={t('data_related_to_dataset')}
      options={options}
      name="dataRelatedToDataset"
      rules={{ required: true }}
      infoTooltip={
        <Stack gap="xs">
          {t('data_related_to_dataset.tooltip')}
          <Button
            variant="linkPrimary"
            as="a"
            href="https://www.liander.nl/over-ons/open-data"
            target="_blank noreferror noopener"
          >
            {t('data_request_receiver.Liander')}
          </Button>
          <Button
            variant="linkPrimary"
            as="a"
            href="https://www.stedin.net/zakelijk/open-data"
            target="_blank noreferror no"
          >
            {t('data_request_receiver.Stedin')}
          </Button>
          <Button
            variant="linkPrimary"
            as="a"
            href="https://www.enexis.nl/over-ons/open-data"
            target="_blank noreferror noopener"
          >
            {t('data_request_receiver.Enexis')}
          </Button>
          <Button
            variant="linkPrimary"
            as="a"
            href="https://www.rendonetwerken.nl/open-data/"
            target="_blank noreferror noopener"
          >
            {t('data_request_receiver.Rendo')}
          </Button>
          <Button
            variant="linkPrimary"
            as="a"
            href="https://coteqnetbeheer.nl/open-data"
            target="_blank noreferror noopener"
          >
            {t('data_request_receiver.Coteq')}
          </Button>
          <Button
            variant="linkPrimary"
            as="a"
            href="https://www.westlandinfra.nl/over-westland-infra/duurzaamheid-innovaties/open-data"
            target="_blank noreferror noopener"
          >
            {t('data_request_receiver.WestlandInfra')}
          </Button>
        </Stack>
      }
    />
  );
};
