import { useTranslation } from 'react-i18next';
import { Button } from '#pie/components/button/Button';
import { Dialog } from '#pie/components/layout/dialog/Dialog';
import { Text } from '#pie/components/text/Text';

export const TicketTemplateDialog = ({
  showConfirm,
  closeConfirm,
  onConfirmClick,
}: {
  showConfirm: boolean;
  closeConfirm: () => void;
  onConfirmClick: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={showConfirm} title={t('ticket_create.categories.switch_category')} onOpenChange={closeConfirm}>
      <Text className="mb-6">{t('ticket_create.categories.switch_category_warning')}</Text>
      <form onSubmit={onConfirmClick}>
        <div className="grid grid-cols-2 gap-4">
          <Button variant="ghost" type="submit">
            {t('common.ignore_changes')}
          </Button>
          <Button variant="secondary" onClick={closeConfirm}>
            {t('common.save_changes')}
          </Button>
        </div>
      </form>
    </Dialog>
  );
};
