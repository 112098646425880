import { useTranslation } from 'react-i18next';
import type { CreateDataRequestDto } from '#edsn/api/pie-bff';
import { DataLevel, DataLevelZipCode } from '#edsn/api/pie-bff';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { ConditionalField, FormRadio, FormText, FormFile } = typedFormFields<CreateDataRequestDto>();

export const PartitionLevelZipCode = () => {
  const { t } = useTranslation('dataRequest');

  return (
    <>
      <ConditionalField
        field="partitionFile"
        condition={formValue => formValue('partitionLevel') === DataLevel.ZipCode}
      >
        <FormFile name="partitionFile" label={t('partition_level_zip_code_file')} rules={{ required: true }} />
      </ConditionalField>
      <ConditionalField
        field="partitionLevelZipCode"
        condition={formValue => formValue('partitionLevel') === DataLevel.ZipCode}
      >
        <FormRadio
          label={t('partition_level_zip_code')}
          name="partitionLevelZipCode"
          options={Object.keys(DataLevelZipCode).map(o => ({
            label: t(`partition_level_zip_code.${o as keyof typeof DataLevelZipCode}`),
            renderInput:
              o === DataLevel.Other ? (
                <ConditionalField
                  field="partitionLevelZipCodeNote"
                  condition={fieldValue => fieldValue('partitionLevelZipCode') === DataLevelZipCode.Other}
                >
                  <FormText
                    name="partitionLevelZipCodeNote"
                    fullWidth
                    showWordCount
                    rules={{ maxLength: 200, minLength: 2, required: true }}
                  />
                </ConditionalField>
              ) : undefined,
            value: o,
          }))}
          rules={{ required: true }}
        />
      </ConditionalField>
    </>
  );
};
