import { useTranslation } from 'react-i18next';
import type { CreateDataRequestDto } from '#edsn/api/pie-bff';
import { DataRelatedToDataset, ReasonForDataRequest } from '#edsn/api/pie-bff';
import type { Option } from '#pie/components/input-radio/InputRadio';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const Reason = () => {
  const { t } = useTranslation('dataRequest');
  const { FormRadio, ConditionalField, FormText } = typedFormFields<CreateDataRequestDto>();

  const options: Option[] = [
    {
      label: t(`reason_for_data_request.${ReasonForDataRequest.EnergySaving}`),
      value: ReasonForDataRequest.EnergySaving,
    },
    {
      label: t(`reason_for_data_request.${ReasonForDataRequest.Monitoring}`),
      value: ReasonForDataRequest.Monitoring,
    },
    { label: t(`reason_for_data_request.${ReasonForDataRequest.Planning}`), value: ReasonForDataRequest.Planning },
    { label: t(`reason_for_data_request.${ReasonForDataRequest.Research}`), value: ReasonForDataRequest.Research },
    {
      label: t(`reason_for_data_request.${ReasonForDataRequest.LegalObligation}`),
      value: ReasonForDataRequest.LegalObligation,
    },
    {
      label: t(`reason_for_data_request.${ReasonForDataRequest.Other}`),
      renderInput: (
        <ConditionalField
          field="reasonNote"
          condition={fieldValue => {
            return fieldValue('reason') === ReasonForDataRequest.Other;
          }}
        >
          <FormText
            aria-label={t('reason_note')}
            name="reasonNote"
            fullWidth
            showWordCount
            rules={{ maxLength: 30, minLength: 5, required: true }}
          />
        </ConditionalField>
      ),
      value: ReasonForDataRequest.Other,
    },
  ];

  return (
    <ConditionalField
      field="reason"
      condition={fieldValue => fieldValue('dataRelatedToDataset') === DataRelatedToDataset.No}
    >
      <FormRadio label={t('reason')} options={options} name="reason" rules={{ required: true }} />
    </ConditionalField>
  );
};
