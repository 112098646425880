import { useEffect, useState } from 'react';
import { screens } from '../theme/tailwindTheme';

type ScreenSize = keyof typeof screens;
export const screenSizes = Object.keys(screens) as ScreenSize[];

const mqls = new Map(
  Object.entries(screens)
    .reverse()
    .map(([key, value]) => [key, window.matchMedia(`(min-width: ${value})`)])
);

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState<ScreenSize | undefined>();
  useEffect(() => {
    const checkScreenSize = () => {
      for (const [key, matchMedia] of mqls) {
        /* c8 ignore next 4*/
        if (matchMedia.matches) {
          setScreenSize(key as ScreenSize);
          return;
        }
      }
    };
    checkScreenSize();
    Array.from(mqls.values()).map(mq => mq.addEventListener('change', checkScreenSize));
    return () => {
      Array.from(mqls.values()).map(mq => mq.removeEventListener('change', checkScreenSize));
    };
  }, []);

  return screenSize;
};
