import { useSetAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { SubmitHandler } from 'react-hook-form';
import { Button } from '#pie/components/button/Button';
import { Card } from '#pie/components/card/Card';
import { ErrorMessage } from '#pie/components/form/errorMaps';
import { PageHeader } from '#pie/components/page/Page';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { searchAtom } from '#pie/stores/search';
import { isValidEan } from '#pie/utils/isValidEan';
import { typedFormFields } from '#pie/utils/typedFormFields';

interface SearchForm {
  search: string;
}

const { FormText } = typedFormFields<SearchForm>();

export const ConnectionInformationForm = () => {
  const { t } = useTranslation();
  const setSearch = useSetAtom(searchAtom);

  const formMethods = useForm<SearchForm>();

  const { handleSubmit } = formMethods;

  const onSubmit: SubmitHandler<SearchForm> = async data => {
    setSearch(data.search);
  };

  return (
    <>
      <PageHeader title={t('connection_information_detail.title')} className="mb-8" />
      <Stack gap="lg" className="mx-auto my-6 max-w-4xl">
        <Card
          size="md"
          heading={
            <Text variant="h5" as="h2">
              {t('connection_information_form.title')}
            </Text>
          }
        >
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-wrap gap-4">
              <Text>{t('connection_information_form.text')}</Text>

              <Stack direction="row" className="flex w-full items-start gap-4">
                <FormText
                  name="search"
                  label={t('connection_information_form.label')}
                  placeholder={t('connection_information_form.placeholder')}
                  type="text"
                  fullWidth
                  rules={{
                    maxLength: 18,
                    required: true,
                    validate: value => isValidEan(value) || t(ErrorMessage.ean18),
                  }}
                />

                <Button type="submit" variant="secondary" className="mt-[1.925rem]">
                  {t('connection_information_form.button')}
                </Button>
              </Stack>
            </form>
          </FormProvider>
        </Card>
      </Stack>
    </>
  );
};
