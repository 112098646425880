import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { CreateUpdateContactDetailsDto } from '#edsn/api/pie-bff';
import { AuthorizationRoles, useGetTeam } from '#edsn/api/pie-bff';
import type { ReactNode } from 'react';
import type { DefaultValues, SubmitHandler } from 'react-hook-form';
import { Button } from '#pie/components/button/Button';
import { Card } from '#pie/components/card/Card';
import { InfoDialog } from '#pie/components/info-dialog/InfoDialog';
import { Page, PageHeader } from '#pie/components/page/Page';
import { SkeletonLines } from '#pie/components/skeleton-lines/SkeletonLines';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { isDefined } from '#pie/utils/isDefined';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { FormText, FormCheckbox } = typedFormFields<CreateUpdateContactDetailsDto>();

interface Props {
  onSubmit: SubmitHandler<CreateUpdateContactDetailsDto>;
  isLoading: boolean;
  defaultValues?: DefaultValues<CreateUpdateContactDetailsDto>;
  action?: ReactNode;
  confirmationDialog?: ReactNode;
}

export const FormOrganisationUser = ({ onSubmit, isLoading, defaultValues, action, confirmationDialog }: Props) => {
  const formMethods = useForm<CreateUpdateContactDetailsDto>({ defaultValues });
  const { t: tcom } = useTranslation();
  const { t: tom } = useTranslation('organisationManagement');
  const { data, isLoading: isLoadingTeams } = useGetTeam();
  const isUpdate = defaultValues && Object.keys(defaultValues).length > 0;

  return (
    <Page>
      <FormProvider {...formMethods}>
        {/* NOTE: added due to react testing library not being able to find the form otherwise */}
        {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
        <form role="form" onSubmit={formMethods.handleSubmit(onSubmit)}>
          <PageHeader
            title={
              isUpdate
                ? tom('header.title.user', {
                    user: `${defaultValues.firstName} ${defaultValues.lastName}`.trim(),
                  })
                : tom('header.title.new_user')
            }
            previousText={tom('header.back')}
            backHref="/organisatiebeheer/gebruikersbeheer"
          >
            <Stack direction="row" gap="md">
              {action}
              <Button
                type="submit"
                isLoading={isLoading}
                variant="secondary"
                isDisabled={isUpdate && !formMethods.formState.isDirty}
              >
                {isUpdate ? tom('header.button.submit_changes') : tom('header.button.submit.create')}
              </Button>
            </Stack>
          </PageHeader>
          <Stack gap="lg" className="mx-auto my-6 max-w-4xl">
            <Card
              size="md"
              heading={
                <Text as="h6" variant="h6">
                  {tcom('common.personal_info')}
                </Text>
              }
              shadow={false}
            >
              <div className="grid grid-cols-2 gap-x-6 gap-y-4">
                <FormText name="firstName" label={tcom('common.first_name')} fullWidth rules={{ required: true }} />
                <FormText
                  name="lastName"
                  label={tcom('common.last_name_prefix')}
                  fullWidth
                  rules={{ required: true }}
                />
                <FormText name="email" type="email" label={tcom('common.email')} fullWidth rules={{ required: true }} />
                <FormText type="tel" name="phone" label={tcom('common.phone')} fullWidth />
              </div>
            </Card>
            <Card
              size="md"
              heading={
                <Text as="h6" variant="h6">
                  {tom('roles.title')}
                </Text>
              }
              shadow={false}
            >
              <Stack gap="md">
                <FormCheckbox
                  label={tom('roles.label')}
                  name="authorizationRoles"
                  options={Object.values(AuthorizationRoles)
                    .map(r =>
                      r !== AuthorizationRoles.Admin && r !== AuthorizationRoles.SuperUser
                        ? {
                            card: true,
                            id: r,
                            label: tcom(`common.roles.${r}`),
                            value: r,
                          }
                        : undefined
                    )
                    .filter(isDefined)}
                />
              </Stack>
            </Card>
            <Card
              size="md"
              heading={
                <Stack direction="row" gap="xs" className="text-primary-dark">
                  <Text as="h6" variant="h6">
                    {tcom('common.teams')}{' '}
                  </Text>
                  <InfoDialog title={tom('teams.info.title.about_teams')}>{tom('teams.info_message')}</InfoDialog>
                </Stack>
              }
              shadow={false}
            >
              <Stack gap="md">
                {isLoadingTeams ? (
                  <SkeletonLines lines={3} />
                ) : data?.items && data.items.length > 0 ? (
                  <FormCheckbox
                    label={tom('teams.label.select_team')}
                    name="teamIds"
                    options={data!.items.map(item => ({ card: true, id: item.id, label: item.name, value: item.id }))}
                  />
                ) : (
                  <Text>{tom('teams.empty')}</Text>
                )}
              </Stack>
            </Card>
          </Stack>
          {confirmationDialog}
        </form>
      </FormProvider>
    </Page>
  );
};
