import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { CreateDataRequestDto } from '#edsn/api/pie-bff';
import { DataLevel, DataRelatedToDataset } from '#edsn/api/pie-bff';
import type { Option } from '#pie/components/input-radio/InputRadio';
import { Button } from '#pie/components/button/Button';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const Level = () => {
  const { t } = useTranslation('dataRequest');
  const { watch } = useFormContext<CreateDataRequestDto>();
  const { ConditionalField, FormRadio, FormText } = typedFormFields<CreateDataRequestDto>();

  const options: Option[] = [
    {
      label: t(`level.${DataLevel.National}`),
      value: DataLevel.National,
    },
    {
      label: t(`level.${DataLevel.ZipCodeRegionalGridOperator}`, {
        netOperator: watch('dataRequestTo')
          ? watch('dataRequestTo')!
              .map(drr => t(`data_request_receiver.${drr}`))
              .join(', ')
          : t('data_request_receiver.AllGridOperators'),
      }),
      value: DataLevel.ZipCodeRegionalGridOperator,
    },
    {
      label: t(`level.${DataLevel.Province}`),
      value: DataLevel.Province,
    },
    {
      label: t(`level.${DataLevel.ResRegion}`),
      tooltip: [
        <>
          {t('level.ResRegion.tooltip.part_one')}
          <Button
            variant="linkPrimary"
            as="a"
            href="https://www.regionale-energiestrategie.nl/resregios/default.aspx"
            target="_blank noreferror noopener"
          >
            {t('level.ResRegion.tooltip.part_link')}
          </Button>
          {t('level.ResRegion.tooltip.part_two')}
        </>,
      ],

      value: DataLevel.ResRegion,
    },
    {
      label: t(`level.${DataLevel.Municipality}`),
      value: DataLevel.Municipality,
    },
    {
      label: t(`level.${DataLevel.City}`),
      value: DataLevel.City,
    },
    {
      label: t(`level.${DataLevel.ZipCode}`),
      value: DataLevel.ZipCode,
    },
    {
      label: t(`level.${DataLevel.Address}`),
      value: DataLevel.Address,
    },
    {
      label: t(`level.Other`),
      renderInput: (
        <ConditionalField field="levelNote" condition={fieldValue => fieldValue('level') === DataLevel.Other}>
          <FormText
            aria-label={t('level_note')}
            fullWidth
            name="levelNote"
            showWordCount
            rules={{ maxLength: 25, minLength: 2, required: true }}
          />
        </ConditionalField>
      ),
      value: DataLevel.Other,
    },
  ];

  return (
    <ConditionalField
      field="level"
      condition={formValue =>
        !!formValue('dataTypeNote') &&
        formValue('dataTypeNote')!.length > 1 &&
        formValue('dataRelatedToDataset') !== DataRelatedToDataset.NoEnergyNeighbourScan
      }
    >
      <FormRadio
        label={t('level')}
        name="level"
        options={options}
        infoTooltip={t('level.tooltip')}
        rules={{ required: true }}
      />
    </ConditionalField>
  );
};
