import * as RadioGroup from '@radix-ui/react-radio-group';
import { Fragment, forwardRef } from 'react';
import { InfoTooltip } from '../info-tooltip/InfoTooltip';
import type { VariantProps } from '#pie/utils/TailwindUtils';
import type { ReactNode } from 'react';
import { tv } from '#pie/utils/TailwindUtils';

export type Option = {
  label: string;
  value: string;
  renderInput?: ReactNode;
  tooltip?: ReactNode;
};

export interface InputRadioProps
  extends Omit<RadioGroup.RadioGroupProps, 'onChange' | 'disabled'>,
    VariantProps<typeof inputRadio> {
  options: Option[];
  className?: string;
  onChange?: (value: string) => void;
}

export const InputRadio = forwardRef<HTMLDivElement, InputRadioProps>(function InputRadio(
  { options, className, onChange, disabled, isError, ...props },
  ref
) {
  const s = inputRadio({ disabled, isError });

  return (
    <RadioGroup.Root
      className={s.base({ className })}
      onValueChange={onChange}
      disabled={disabled}
      ref={ref}
      {...props}
    >
      {options.map((option, idx) => (
        <Fragment key={idx}>
          <label className="flex cursor-pointer items-center gap-2">
            <RadioGroup.Item id={`${props.name}-${option.value}`} value={option.value} className={s.item()}>
              <RadioGroup.Indicator className={s.indicator()} />
            </RadioGroup.Item>

            <span className={s.label()}>
              {option.label} {option.tooltip && <InfoTooltip>{option.tooltip}</InfoTooltip>}
            </span>
          </label>
          {option.renderInput}
        </Fragment>
      ))}
    </RadioGroup.Root>
  );
});

const inputRadio = tv({
  base: 'flex flex-col gap-2',
  slots: {
    indicator:
      "after:bg-primary-light :disabled:opacity-50 relative flex h-full w-full items-center justify-center after:block after:h-2.5 after:w-2.5 after:rounded-[50%] after:content-['']",
    item: 'data-[state=checked]:border-primary-light h-4 w-4 cursor-pointer rounded-full border border-input bg-white shadow-inputEmphasis outline-none flex-shrink-0',
    label: 'text-neutral-600 cursor-pointer',
  },
  variants: {
    disabled: {
      true: {
        indicator: 'after:bg-neutral-300',
        item: 'data-[state=checked]:border-input cursor-not-allowed',
        label: 'text-neutral-300 cursor-not-allowed',
      },
    },
    isError: {
      true: {
        indicator: 'after:bg-error',
        item: 'data-[state=checked]:border-error border-error bg-error-light',
        label: 'text-error',
      },
    },
  },
});
