import * as Portal from '@radix-ui/react-portal';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  type NotificationDto,
  type UpdateNotificationDto,
  getGetNotificationQueryKey,
  useGetNotification,
  usePutNotification,
} from '#edsn/api/pie-bff';
import { NotificationsCategories } from './NotificationsCategories';
import type { SubmitHandler } from 'react-hook-form';
import { Button } from '#pie/components/button/Button';
import { Card } from '#pie/components/card/Card';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { useToast } from '#pie/components/toast/ToastContext';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const Notifications = () => {
  const { t: tom } = useTranslation('organisationManagement');
  const { FormText, FormToggle } = typedFormFields<NotificationDto>();
  const formMethods = useForm<NotificationDto>();
  const { data } = useGetNotification();
  const { handleSubmit } = formMethods;
  const ref = useRef<HTMLFormElement>(null);
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const isChecked = formMethods.watch('enabled');

  useEffect(() => {
    formMethods.reset({ ...data });
  }, [data]);

  const { mutate, isLoading } = usePutNotification({
    mutation: {
      onError: () => {
        addToast({
          message: `${tom('toast.error.message_adjusting_email')}`,
          title: `${tom('toast.error.title_adjusting_email')}`,
          type: 'error',
        });
      },
      onSuccess: (_data, variables) => {
        gtag('event', 'delete_team_member', {});
        addToast({
          message: `${tom('toast.success.message_adujsting_email', {
            email: variables.data.email,
          })}`,
          title: `${tom('toast.success.title_email_adjusted')}`,
          type: 'success',
        });
        queryClient.invalidateQueries(getGetNotificationQueryKey());
      },
    },
  });

  const onSubmit: SubmitHandler<UpdateNotificationDto> = data => {
    mutate({ data });
  };

  return (
    <Stack gap="lg" className="mx-auto my-6 max-w-4xl">
      <Card
        size="md"
        heading={
          <Text as="h2" variant="h6" className="text-primary-dark">
            {tom('settings.title_notifications')}
          </Text>
        }
        shadow={false}
      >
        <Stack gap="md">
          <Text className="">{tom('settings.description.email_notifications')}</Text>
          <FormProvider {...formMethods}>
            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
            <form role="form" ref={ref} onSubmit={handleSubmit(onSubmit)}>
              <Stack gap="md">
                <FormToggle
                  label={tom('tab.notifications')}
                  option={{ label: tom('settings.toggle.label.email_notification') }}
                  name="enabled"
                />
                {isChecked && (
                  <FormText fullWidth name="email" label={tom('email_label')} rules={{ required: true }}></FormText>
                )}
              </Stack>
              <Portal.Root container={document.getElementById('pageheader-button')}>
                <Button
                  isDisabled={!formMethods.formState.isDirty}
                  variant="secondary"
                  onClick={() => {
                    ref.current?.requestSubmit();
                  }}
                  isLoading={isLoading}
                >
                  {tom('header.button.submit_changes')}
                </Button>
              </Portal.Root>
            </form>
          </FormProvider>
        </Stack>
      </Card>
      {isChecked && <NotificationsCategories />}
    </Stack>
  );
};
