import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { CreateDataRequestDto } from '#edsn/api/pie-bff';
import { DataLevel, DataLevelZipCode } from '#edsn/api/pie-bff';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { ConditionalField, FormRadio, FormText } = typedFormFields<CreateDataRequestDto>();

export const PartitionLevel = () => {
  const { t } = useTranslation('dataRequest');

  const { watch } = useFormContext<CreateDataRequestDto>();

  const options = [
    {
      label: t(`partition_level.${DataLevel.National}`),
      value: DataLevel.National,
    },
    {
      label: t(`partition_level.${DataLevel.ZipCodeRegionalGridOperator}`),
      value: DataLevel.ZipCodeRegionalGridOperator,
    },
    {
      label: t(`partition_level.${DataLevel.Province}`),
      value: DataLevel.Province,
    },
    {
      label: t(`partition_level.${DataLevel.ResRegion}`),
      value: DataLevel.ResRegion,
    },
    {
      label: t(`partition_level.${DataLevel.Municipality}`),
      value: DataLevel.Municipality,
    },
    {
      label: t(`partition_level.${DataLevel.City}`),
      value: DataLevel.City,
    },
    {
      label: t(`partition_level.ZipCode`),
      value: DataLevel.ZipCode,
    },
    {
      label: t(`partition_level.Address`),
      value: DataLevel.Address,
    },
    {
      label: t(`partition_level.${DataLevel.Other}`),
      renderInput: (
        <ConditionalField
          field="partitionLevelNote"
          condition={fieldValue => fieldValue('partitionLevel') === DataLevel.Other}
        >
          <FormText
            aria-label={t('partition_level_note')}
            name="partitionLevelNote"
            fullWidth
            showWordCount
            rules={{ maxLength: 200, minLength: 2, required: true }}
          />
        </ConditionalField>
      ),
      value: DataLevel.Other,
    },
  ];

  const isOptionVisible = (option: DataLevel) => {
    const level = watch('level');
    const hiddenOptions: Partial<Record<DataLevel, DataLevel[]>> = {
      [DataLevel.ZipCodeRegionalGridOperator]: [DataLevel.National],
      [DataLevel.Province]: [DataLevel.National, DataLevel.ZipCodeRegionalGridOperator],
      [DataLevel.Municipality]: [
        DataLevel.National,
        DataLevel.ZipCodeRegionalGridOperator,
        DataLevel.Province,
        DataLevel.ResRegion,
      ],
      [DataLevel.City]: [
        DataLevel.National,
        DataLevel.ZipCodeRegionalGridOperator,
        DataLevel.Province,
        DataLevel.Municipality,
        DataLevel.ResRegion,
      ],
      [DataLevel.ResRegion]: [DataLevel.National, DataLevel.ZipCodeRegionalGridOperator, DataLevel.Province],
      [DataLevel.ZipCode]: Object.values(DataLevel).filter(o => o !== DataLevel.Address && o !== DataLevel.Other),
    };

    return level ? !hiddenOptions[level]?.includes(option) : true;
  };

  return (
    <ConditionalField
      field="partitionLevel"
      condition={formValue => {
        const hasLevelNote = !!formValue('levelNote') && formValue('levelNote')!.length > 1;
        const isSpecificLevel =
          formValue('level') === DataLevel.National || formValue('level') === DataLevel.ZipCodeRegionalGridOperator;
        const hasValidZipCodeLevel =
          !!formValue('zipCodeLevel') && formValue('zipCodeLevel') !== DataLevelZipCode.Other;
        const hasZipCodeLevelNote = !!formValue('zipCodeLevelNote') && formValue('zipCodeLevelNote')!.length > 1;
        const isNotAddressLevel = formValue('level') !== DataLevel.Address;

        return (hasLevelNote || isSpecificLevel || hasValidZipCodeLevel || hasZipCodeLevelNote) && isNotAddressLevel;
      }}
    >
      <FormRadio
        label={t('data_services.partition_level')}
        infoTooltip={t('data_services.partition_level.tooltip')}
        name="partitionLevel"
        options={options.filter(o => isOptionVisible(o.value as DataLevel))}
        rules={{ required: true }}
      />
    </ConditionalField>
  );
};
