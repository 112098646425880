import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { CreateTicketDto } from '#edsn/api/pie-bff';
import type { ClipboardEvent, DragEvent } from 'react';
import { typedFormFields } from '#pie/utils/typedFormFields';

type TicketType = CreateTicketDto & { files: { file: File }[] };
export const TicketDescription = () => {
  const { t } = useTranslation();
  const { FormTextArea } = typedFormFields<TicketType>();

  const { control } = useFormContext<TicketType>();

  const { append } = useFieldArray<TicketType>({
    control,
    name: 'files',
  });

  const addFiles = (files: File[]) => {
    if (files.length) {
      const _files = files.map((file: File) => {
        return { file };
      });
      append(_files);
    }
  };

  const onPaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
    if (e.clipboardData.files.length) {
      addFiles([...e.clipboardData.files]);
    }
  };

  const onDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files.length) {
      addFiles([...e.dataTransfer.files]);
    }
  };

  return (
    <FormTextArea
      label={t('common.description')}
      fullWidth
      name="description"
      rows={8}
      showWordCount
      rules={{ maxLength: 2000 }}
      onPaste={onPaste}
      onDragOver={e => e.preventDefault()}
      onDrop={onDrop}
    />
  );
};
