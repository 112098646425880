import AddIcon from '@iconify/icons-material-symbols/add';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ContactGroupManagement } from './cpr/ContactGroupManagement';
import { ContactPersonManagement } from './cpr/ContactPersonManagement';
import { Notifications } from './notifications/Notifications';
import { TeamManagement } from './teams/TeamManagement';
import { UserManagement } from './users/UserManagement';
import { useUserHasRole } from '#pie/auth/useUserHasRole';
import { Button } from '#pie/components/button/Button';
import { CreateContactModal } from '#pie/components/contact-modal/CreateContactModal';
import { CreateTeamForm } from '#pie/components/create-team-form/CreateTeamForm';
import { Page, PageHeader } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Stack } from '#pie/components/stack/Stack';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '#pie/components/tabs';
import { UserManagementExport } from '#pie/components/user-management-export/UserManagementExport';

export enum OrganisationTabs {
  UserManagement = 'gebruikersbeheer',
  TeamManagement = 'teamsbeheer',
  Notifications = 'notificaties',
  ContactGroupManagement = 'contactgroepenbeheer',
  ContactPersonManagement = 'contactpersonenbeheer',
}

export const OrganisationManagement = () => {
  const { t: tcom } = useTranslation();
  const { t: tom } = useTranslation('organisationManagement');
  const location = useLocation();
  const navigate = useNavigate();

  const tab = location.pathname.split('/').at(2) as OrganisationTabs;

  const hasCprAdminRole = useUserHasRole({ role: 'CprAdmin' });
  const hasManagementRole = useUserHasRole({ role: 'SuperUser' });

  useEffect(() => {
    if (!tab && (hasCprAdminRole || hasManagementRole)) {
      if (hasManagementRole) {
        navigate(`/organisatiebeheer/${OrganisationTabs.UserManagement}`, { replace: true });
      } else if (hasCprAdminRole) {
        navigate(`/organisatiebeheer/${OrganisationTabs.ContactGroupManagement}`, { replace: true });
      }
    }
  }, [hasCprAdminRole, hasManagementRole]);

  return (
    <>
      <PageHead title={tom('title')} />
      <Page>
        <PageHeader title={tom('title')} className="mb-8">
          {tab === OrganisationTabs.UserManagement && (
            <>
              <UserManagementExport />
              <Button as={Link} iconStart={AddIcon} to="/organisatiebeheer/gebruikersbeheer/nieuw" variant="secondary">
                {tom('header.button.new_user')}
              </Button>
            </>
          )}
          {tab === OrganisationTabs.TeamManagement && <CreateTeamForm />}
          {tab === OrganisationTabs.ContactPersonManagement && <CreateContactModal />}
        </PageHeader>
        <section className="flex items-start gap-6">
          <Tabs asChild value={tab} onValueChange={val => navigate(`../${val}`)}>
            <Stack gap="lg" className="w-full">
              <TabsList>
                {hasManagementRole && (
                  <>
                    <TabsTrigger value={OrganisationTabs.UserManagement}>{tom('tab.user_management')}</TabsTrigger>
                    <TabsTrigger value={OrganisationTabs.TeamManagement}>{tcom('common.teams')}</TabsTrigger>
                    <TabsTrigger value={OrganisationTabs.Notifications}>{tom('tab.notifications')}</TabsTrigger>
                  </>
                )}
                {hasCprAdminRole && (
                  <>
                    <TabsTrigger value={OrganisationTabs.ContactGroupManagement}>
                      {tcom('common.tab.contact_group')}
                    </TabsTrigger>
                    <TabsTrigger value={OrganisationTabs.ContactPersonManagement}>
                      {tcom('common.tab.contact')}
                    </TabsTrigger>
                  </>
                )}
              </TabsList>
              {hasManagementRole && (
                <>
                  <TabsContent value={OrganisationTabs.UserManagement}>
                    <UserManagement />
                  </TabsContent>
                  <TabsContent value={OrganisationTabs.TeamManagement}>
                    <TeamManagement />
                  </TabsContent>
                  <TabsContent value={OrganisationTabs.Notifications}>
                    <Notifications />
                  </TabsContent>
                </>
              )}
              {hasCprAdminRole && (
                <>
                  <TabsContent value={OrganisationTabs.ContactGroupManagement}>
                    <ContactGroupManagement />
                  </TabsContent>
                  <TabsContent value={OrganisationTabs.ContactPersonManagement}>
                    <ContactPersonManagement />
                  </TabsContent>
                </>
              )}
            </Stack>
          </Tabs>
        </section>
      </Page>
    </>
  );
};
